@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  transition-property: transform, color, background-color, border-color,
    text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 130ms;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

html {
  scroll-snap-type: none;
  overflow: hidden;
  width: 100vw;
}

html,
body {
  background-color: rgb(2 132 199);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
}

*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
*::-webkit-scrollbar-track {
  margin-top: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: rgb(7 89 133);
  border-radius: 10px;
}

#careers {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (min-width: 1024px) {
  .photobanner {
    position: absolute;
    left: 0px;
    animation: bannermove 30s linear infinite;
    whitespace: nowrap;
  }

  @keyframes bannermove {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(calc(-100% + 2rem), 0);
    }
  }
}

.white-border-font {
  -webkit-text-stroke: 1px white;
}

.transition-height {
  transition-property: max-height;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  overflow: hidden;
}
